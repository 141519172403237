import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'
import ToastificationContentCustom from '../ToastificationContentCustom.vue'

// Notification

export default function useBudgetaryPeriodList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refBudgetaryPeriodTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'start',
      label: 'budgetary period',
      sortable: true,
    },
    {
      key: 'createdAt',
      label: 'created on',
      sortable: true,
    },
    {
      key: 'status',
      label: 'status',
      sortable: true,
    },
    {
      key: 'actions',
      label: '',
      sortable: false,
      tdClass: 'text-right',
    },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalBudgetaryPeriods = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const sortBy = ref(route.query.sortBy ?? 'start')
  const isSortDirDesc = ref(route.query.sortDesc ? (route.query.sortDesc === 'true') : true)
  const toDeletedID = ref(null)
  const deletedPeriodName = ref('')
  const currentDataLength = ref(0)
  let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refBudgetaryPeriodTable.value ? refBudgetaryPeriodTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalBudgetaryPeriods.value,
    }
  })

  const refetchData = () => {
    refBudgetaryPeriodTable.value.refresh()
  }

  watch([searchQuery], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchBudgetaryPeriods = (ctx, callback) => {
    store
      .dispatch('app-budgetary-periods/fetchBudgetaryPeriods', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      },
      { root: true })
      .then(response => {
        const { budgetPeriods, total } = response.data
        currentDataLength.value = budgetPeriods.length
        callback(budgetPeriods)
        totalBudgetaryPeriods.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteBudgetaryPeriod = () => {
    store
      .dispatch('app-budgetary-periods/deleteBudgetaryPeriod', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(() => {
        refetchData()
        const toastMessage = `${deletedPeriodName.value} has been deleted.`
        toast({
          component: ToastificationContentCustom,
          props: {
            title: 'Budgetary Period is deleted',
            text: toastMessage,
            icon: 'Trash2Icon',
            variant: 'danger',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveBudgetaryPeriodStatusVariant = status => {
    if (status === 'active') return 'success'
    if (status === 'view only') return 'warning'
    return 'primary'
  }

  return {
    fetchBudgetaryPeriods,
    tableColumns,
    perPage,
    currentPage,
    totalBudgetaryPeriods,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refBudgetaryPeriodTable,

    resolveBudgetaryPeriodStatusVariant,
    refetchData,

    toDeletedID,
    deletedPeriodName,
    deleteBudgetaryPeriod,
    currentDataLength,
  }
}
