<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="7"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="custom-header-title float-left pr-1 mb-0">
              Budgetary Periods
            </h2>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-right mb-1"
        md="3"
        cols="5"
      >
        <b-button
          v-if="canViewThisAction('create', 'BudgetPeriod')"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mobile_create_button"
          @click="openCreateNewPeriodModal()"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle d-md-block d-none">Create New Budgetary Period</span>
          <span class="align-middle d-md-none d-block">New</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refBudgetaryPeriodTable"
        class="position-relative"
        :items="fetchBudgetaryPeriods"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Name -->
        <template #cell(start)="data">
          <div><span class="text-bold-black">{{ `${data.item.name} (${dateFormat(data.item.start)} - ${dateFormat(data.item.end)})` }}</span></div>
        </template>

        <!-- Column: createdAt -->
        <template #cell(createdAt)="data">
          <span class="">{{ dateFormatWithTime(data.item.createdAt) }}</span>
        </template>

        <!-- Column: Status -->
        <template #head(status)="data">
          <div class="">
            {{ data.label }}
            <feather-icon
              id="table-status-tooltip"
              icon="AlertCircleIcon"
              class="mb-25 text-primary"
              size="14"
            />
            <b-tooltip
              placement="auto"
              target="table-status-tooltip"
              title="Budgetary Periods on 'View only' status will not be available in budget setup (in Budgets) and budget allocations (in Purchase Requests)."
            />
          </div>
        </template>

        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveBudgetaryPeriodStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('update', 'BudgetPeriod') || canViewThisAction('delete', 'BudgetPeriod')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="canViewThisAction('update', 'BudgetPeriod')"
              @click="openBudgetaryPeriodEditModal(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('delete', 'BudgetPeriod')"
              @click="deleteEntity(data.item._id, data.item.name, data.item.start, data.item.end)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalBudgetaryPeriods > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalBudgetaryPeriods"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
    <!-- create new budgetary period modal -->
    <b-modal
      id="create-budgetary-period-modal"
      ref="create-budgetary-period-modal"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Create New Budgetary Period"
      @cancel="closeCreateNewPeriodModal"
      @ok="submitBudgetaryPeriodCreateForm"
    >
      <b-form @submit.prevent="submitBudgetaryPeriodCreateForm">
        <validation-observer
          ref="allBudgetaryPeriodCreateForm"
        >
          <b-form-group
            label="Name*"
            label-for="budgetary-period-name"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              vid="name"
              rules="required"
            >
              <b-form-input
                id="budgetary-period-name"
                v-model="name"
                placeholder="Enter a name"
                :state="(errors.length > 0 || nameValidation) ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="nameValidation"
                class="text-danger"
              >
                {{ nameError }}
              </small>
            </validation-provider>
          </b-form-group>
          <div class="period-date">
            <b-form-group
              label="Start Date*"
              label-for="budgetary-period-start-date"
            >
              <validation-provider
                #default="{ errors }"
                name="Start Date"
                vid="startDate"
                rules="required"
              >
                <b-input-group>
                  <flat-pickr
                    id="budgetary-period-start-date"
                    v-model="startDate"
                    class="form-control flat-pickr-group"
                    placeholder="Select start Date"
                    :config="flatPickrConfigWithoutTime"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CalendarIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-if="startDateValidation"
                  class="text-danger"
                >
                  {{ startDateError }}
                </small>
              </validation-provider>
            </b-form-group>
            <div style="margin-top: 40px;">
              <feather-icon
                icon="ArrowRightIcon"
                size="20"
              />
            </div>
            <b-form-group
              label="End Date*"
              label-for="budgetary-period-end-date"
            >
              <validation-provider
                #default="{ errors }"
                name="End Date"
                vid="endDate"
                rules="required"
              >
                <b-input-group>
                  <flat-pickr
                    id="budgetary-period-end-date"
                    v-model="endDate"
                    class="form-control flat-pickr-group"
                    placeholder="Select end Date"
                    :config="flatPickrConfigWithoutTime"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CalendarIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-if="endDateValidation"
                  class="text-danger"
                >
                  {{ endDateError }}
                </small>
              </validation-provider>
            </b-form-group>
          </div>
          <b-form-group
            label="Status*"
            label-for="budgetary-status"
            class="status_label"
          >
            <div class="create__form_status_tooltip">
              <feather-icon
                id="status-form-tooltip"
                icon="AlertCircleIcon"
                class="ml-50 text-primary"
                size="14"
              />
              <b-tooltip
                placement="auto"
                target="status-form-tooltip"
                title="Budgetary Periods on 'View only' status will not be available in budget setup (in Budgets) and budget allocations (in Purchase Requests)."
              />
            </div>
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="budgetary-status"
                v-model="status"
                label="title"
                :options="statusOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="buttonStatus"
          @click="ok()"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Create</span>
        </b-button>
      </template>
    </b-modal>
    <!--  /. create new budgetary period modal -->
    <!-- edit budgetary period modal -->
    <b-modal
      id="edit-budgetary-period-modal"
      ref="edit-budgetary-period-modal"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Edit Budgetary Period"
      @cancel="closeBudgetaryPeriodEditModal"
      @ok="submitBudgetaryPeriodEditForm"
    >
      <b-form @submit.prevent="submitBudgetaryPeriodEditForm">
        <validation-observer
          ref="allBudgetaryPeriodEditForm"
        >
          <b-form-group
            label="Name*"
            label-for="budgetary-period-name"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              vid="name"
              rules="required"
            >
              <b-form-input
                id="budgetary-period-name"
                v-model="name"
                placeholder="Enter a name"
                :state="(errors.length > 0 || nameValidation) ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="nameValidation"
                class="text-danger"
              >
                {{ nameError }}
              </small>
            </validation-provider>
          </b-form-group>
          <div class="period-date">
            <b-form-group
              label="Start Date*"
              label-for="budgetary-period-start-date"
            >
              <validation-provider
                #default="{ errors }"
                name="Start Date"
                vid="startDate"
                rules="required"
              >
                <b-input-group>
                  <flat-pickr
                    id="budgetary-period-start-date"
                    v-model="startDate"
                    class="form-control flat-pickr-group"
                    placeholder="Select a Date"
                    :config="flatPickrConfigWithoutTime"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CalendarIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-if="startDateValidation"
                  class="text-danger"
                >
                  {{ startDateError }}
                </small>
              </validation-provider>
            </b-form-group>
            <div style="margin-top: 40px;">
              <feather-icon
                icon="ArrowRightIcon"
                size="20"
              />
            </div>
            <b-form-group
              label="End Date*"
              label-for="budgetary-period-end-date"
            >
              <validation-provider
                #default="{ errors }"
                name="End Date"
                vid="endDate"
                rules="required"
              >
                <b-input-group>
                  <flat-pickr
                    id="budgetary-period-end-date"
                    v-model="endDate"
                    class="form-control flat-pickr-group"
                    placeholder="Select a Date"
                    :config="flatPickrConfigWithoutTime"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CalendarIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-if="endDateValidation"
                  class="text-danger"
                >
                  {{ endDateError }}
                </small>
              </validation-provider>
            </b-form-group>
          </div>
          <b-form-group
            label="Status*"
            label-for="budgetary-status"
            class="status_label"
          >
            <div class="create__form_status_tooltip">
              <feather-icon
                id="status-form-tooltip"
                icon="AlertCircleIcon"
                class="ml-50 text-primary"
                size="14"
              />
              <b-tooltip
                placement="auto"
                target="status-form-tooltip"
                title="Budgetary Periods on 'View only' status will not be available in budget setup (in Budgets) and budget allocations (in Purchase Requests)."
              />
            </div>
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="budgetary-status"
                v-model="status"
                label="title"
                :options="statusOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="buttonStatus"
          @click="ok()"
        >
          <feather-icon
            icon="SaveIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Save Changes</span>
        </b-button>
      </template>
    </b-modal>
    <!--  /. edit budgetary period modal -->
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BForm, BFormGroup, BFormInput, BTable, BInputGroup, BInputGroupAppend,
  BDropdown, BDropdownItem, BPagination, VBTooltip, BTooltip, BBadge,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import { required } from '@validations'
import useBudgetaryPeriodList from './useAllBudgetaryPeriodList'
import budgetaryPeriodStoreModule from './budgetaryPeriodStoreModule'

import ToastificationContentCustom from '../ToastificationContentCustom.vue'

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BForm,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BTooltip,
    BBadge,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
    flatPickr,

    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentCustom,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      buttonStatus: false,
      name: '',
      nameError: 'Name is required',
      nameValidation: false,
      startDate: '',
      startDateValidation: false,
      startDateError: 'Start Date is required',
      endDate: '',
      endDateValidation: false,
      endDateError: 'End Date is required',
      status: 'active',
      statusValidation: false,
      statusError: 'Status is required',
      statusOptions: [
        { title: 'Active', code: 'active' },
        { title: 'View Only', code: 'view only' },
      ],
      flatPickrConfigWithoutTime: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', disableMobile: true,
      },
      budgetPeriodEditId: '',
      // validation rules
      required,
    }
  },

  beforeMount() {
    this.$http.get('inventory/category/respond-with/name-and-id')
      .then(response => {
        this.categoryOptions = response.data.categories ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    openCreateNewPeriodModal() {
      this.name = ''
      this.startDate = ''
      this.endDate = ''
      this.status = 'active'
      this.$root.$emit('bv::show::modal', 'create-budgetary-period-modal', '')
    },
    closeCreateNewPeriodModal() {
      this.$root.$emit('bv::hide::modal', 'create-budgetary-period-modal', '')
    },
    openBudgetaryPeriodEditModal(budgetPeriod) {
      this.name = budgetPeriod.name ?? ''
      this.startDate = this.dateFormat(budgetPeriod.start) ?? ''
      this.endDate = this.dateFormat(budgetPeriod.end) ?? ''
      this.status = budgetPeriod.status ?? ''
      this.budgetPeriodEditId = budgetPeriod._id
      this.$root.$emit('bv::show::modal', 'edit-budgetary-period-modal', '')
    },
    closeBudgetaryPeriodEditModal() {
      this.$root.$emit('bv::hide::modal', 'edit-budgetary-period-modal', '')
    },
    submitBudgetaryPeriodCreateForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.allBudgetaryPeriodCreateForm.validate().then(success => {
        if (success) {
          store.commit('appConfig/UPDATE_LOADER', true)
          this.buttonStatus = true
          const formData = new FormData()
          formData.append('name', this.name)
          formData.append('start', this.startDate)
          formData.append('end', this.endDate)
          formData.append('status', this.status)
          this.$http.post('budget/budget-period/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.refetchData()
              this.buttonStatus = false
              this.closeCreateNewPeriodModal()
              const budgetTitle = `${response.data.data.name} (${this.dateFormat(response.data.data.start)} - ${this.dateFormat(response.data.data.end)})`
              const toastMessage = `Budgetary Period <span class="text-bold-black">${budgetTitle}</span> has been created successfully.`
              this.$toast({
                component: ToastificationContentCustom,
                props: {
                  title: 'Budgetary Period is created!',
                  text: toastMessage,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.name = ''
              this.startDate = ''
              this.endDate = ''
              this.status = 'active'
            })
            .catch(error => {
              if (error.data.errors) {
                this.buttonStatus = false
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  }
                  if (validationError.param === 'start') {
                    this.startDateError = validationError.msg
                    this.startDateValidation = true
                  }
                  if (validationError.param === 'end') {
                    this.endDateError = validationError.msg
                    this.endDateValidation = true
                  }
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.buttonStatus = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    submitBudgetaryPeriodEditForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.allBudgetaryPeriodEditForm.validate().then(success => {
        if (success) {
          store.commit('appConfig/UPDATE_LOADER', true)
          this.buttonStatus = true
          const formData = new FormData()
          formData.append('name', this.name)
          formData.append('start', this.startDate)
          formData.append('end', this.endDate)
          formData.append('status', this.status)
          this.$http.patch(`budget/budget-period/${this.budgetPeriodEditId}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.refetchData()
              this.closeBudgetaryPeriodEditModal()
              this.buttonStatus = false
              const budgetTitle = `${response.data.data.name} (${this.dateFormat(response.data.data.start)} - ${this.dateFormat(response.data.data.end)})`
              const toastMessage = `Budgetary Period <span class="text-bold-black">${budgetTitle}</span> has been updated successfully.`
              this.$toast({
                component: ToastificationContentCustom,
                props: {
                  title: 'Budgetary Period is edited!',
                  text: toastMessage,
                  icon: 'EditIcon',
                  variant: 'primary',
                },
              })
            })
            .catch(error => {
              if (error.data.errors) {
                this.buttonStatus = false
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  }
                  if (validationError.param === 'start') {
                    this.startDateError = validationError.msg
                    this.startDateValidation = true
                  }
                  if (validationError.param === 'end') {
                    this.endDateError = validationError.msg
                    this.endDateValidation = true
                  }
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.buttonStatus = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    deleteEntity(id, name, startDate, endDate) {
      const budgetTitle = `${name} (${this.dateFormat(startDate)} - ${this.dateFormat(endDate)})`
      this.$http.get(`budget/budget-period/${id}/check-existing-budget`)
        .then(response => {
          if (response.data.exists) {
            this.$swal({
              title: `${budgetTitle} cannot be deleted`,
              html: 'This budgetary period already in use and it is not possible to delete it. Do you want to change its status to “View only” instead? <br><br> <b>All budgets under a “View only” budgetary period will no longer be available for allocation to Purchase Requests.</b> <br><br> This action can be reversed later.',
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/icons/warning.png'),
              imageWidth: 80,
              imageHeight: 80,
              imageAlt: 'Custom Icon',
              reverseButtons: true,
              showCancelButton: true,
              confirmButtonText: 'Yes, change to “View only”',
              cancelButtonText: 'No, Go Back',
              customClass: {
                confirmButton: 'btn btn-primary ml-1 mt-1',
                cancelButton: 'btn btn-outline-primary mt-1',
              },
              buttonsStyling: false,
              width: 600,
            })
              .then(confirm => {
                if (confirm.value) {
                  const formData = new FormData()
                  formData.append('status', 'view only')
                  this.$http.patch(`budget/budget-period/${id}/freeze`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                  })
                    .then(() => {
                      this.refetchData()
                      const toastMessage = `${budgetTitle} budget usability has been changed to View Only`
                      this.$toast({
                        component: ToastificationContentCustom,
                        props: {
                          title: 'Budgetary Period status is edited!',
                          text: toastMessage,
                          icon: 'ArchiveIcon',
                          variant: 'warning',
                        },
                      })
                    })
                    .catch(error => {
                      if (error.data.errors) {
                        error.data.errors.forEach(validationError => {
                          if (validationError.param === 'status') {
                            this.statusError = validationError.msg
                            this.statusValidation = true
                          }
                        })
                      } else {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                          },
                        })
                      }
                    })
                }
              })
          } else {
            this.$swal({
              title: 'Are you sure to delete',
              html: `<span style="font-weight: 600; font-size: 24px;">${budgetTitle}?</span> <br> <br> Deleting the Budgetary Period cannot be undone and all the data will be lost. Related budgets may not be accessible too.`,
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/icons/warning.png'),
              imageWidth: 80,
              imageHeight: 80,
              imageAlt: 'Custom Icon',
              reverseButtons: true,
              showCancelButton: true,
              confirmButtonText: 'Yes, Delete!',
              cancelButtonText: 'No, Go Back',
              customClass: {
                confirmButton: 'btn btn-primary ml-1',
                cancelButton: 'btn btn-outline-primary',
              },
              buttonsStyling: false,
              width: 600,
            })
              .then(result => {
                if (result.value) {
                  this.toDeletedID = id
                  this.deletedPeriodName = name
                  this.deleteBudgetaryPeriod()
                }
              })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const BUDGETARY_PERIOD_APP_STORE_MODULE_NAME = 'app-budgetary-periods'

    // Register module
    if (!store.hasModule(BUDGETARY_PERIOD_APP_STORE_MODULE_NAME)) store.registerModule(BUDGETARY_PERIOD_APP_STORE_MODULE_NAME, budgetaryPeriodStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BUDGETARY_PERIOD_APP_STORE_MODULE_NAME)) store.unregisterModule(BUDGETARY_PERIOD_APP_STORE_MODULE_NAME)
    })

    const { canViewThisAction } = useAclUtils()

    const {
      fetchBudgetaryPeriods,
      tableColumns,
      perPage,
      currentPage,
      totalBudgetaryPeriods,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBudgetaryPeriodTable,
      refetchData,

      toDeletedID,
      deletedPeriodName,
      deleteBudgetaryPeriod,
      currentDataLength,

      // UI
      resolveBudgetaryPeriodStatusVariant,
    } = useBudgetaryPeriodList()

    return {
      fetchBudgetaryPeriods,
      tableColumns,
      perPage,
      currentPage,
      totalBudgetaryPeriods,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBudgetaryPeriodTable,
      refetchData,
      showSearchBar,
      toDeletedID,
      deletedPeriodName,
      deleteBudgetaryPeriod,
      currentDataLength,

      // Filter
      avatarText,
      canViewThisAction,

      // UI
      resolveBudgetaryPeriodStatusVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.modal .form-control {
     margin: 0;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
