import { render, staticRenderFns } from "./PeriodList.vue?vue&type=template&id=9b7f155c&scoped=true&"
import script from "./PeriodList.vue?vue&type=script&lang=js&"
export * from "./PeriodList.vue?vue&type=script&lang=js&"
import style0 from "./PeriodList.vue?vue&type=style&index=0&id=9b7f155c&prod&lang=scss&scoped=true&"
import style1 from "./PeriodList.vue?vue&type=style&index=1&id=9b7f155c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b7f155c",
  null
  
)

export default component.exports